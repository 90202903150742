import { checkUserExists } from '@/app/api/cms/auth/service';
import { useRequestOtp } from '@/app/hooks/cms';
import { RegisterDTO, registerResolver } from '@/app/types';
import { checkCarpark, structUrl } from '@/app/utils/helpers';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { FC, useEffect, useMemo, useState } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../button';
import Input from '../input';

const RegisterForm: FC = () => {
    const router = useRouter();
    const searchParams = useSearchParams();
    const carpark = searchParams.get('carpark');
    const charger = searchParams.get('charger');

    const [errMsg, setErrMsg] = useState<string>('');
    const [captcha, setCaptcha] = useState<string | null>(null);

    const [tncConfirm, setTncConfirm] = useState(true);
    const [privacyConfirm, setPrivacyConfirm] = useState(true);
    const requestOtp = useRequestOtp();
    const [loading, setLoading] = useState(false);

    const form = useForm<RegisterDTO>({
        resolver: registerResolver,
        mode: 'onChange',
        defaultValues: {
            name: '',
            carModel: '',
            email: '',
            carPlateNo: '',
            mobileNumber: undefined,
        },
    });

    async function handleRegister(data: RegisterDTO) {
        setErrMsg('');
        form.clearErrors();

        try {
            if (!tncConfirm || !privacyConfirm) {
                throw new Error(
                    'Please accept terms and conditions and privacy policy'
                );
            }

            // if (!carpark) {
            //     throw new Error('Invalid Carpark ID');
            // }

            // const valid = checkCarpark(carpark);

            // if (!valid) {
            //     throw new Error('Carpark ID not available');
            // }

            if (!captcha) {
                throw new Error('Please complete captcha');
            }

            if (String(data.mobileNumber).length !== 8) {
                throw new Error(
                    'Please enter a valid mobile number for Hong Kong (8 Digits, without 852).'
                );
            }

            setLoading(true);

            const exists = await checkUserExists(`${data.mobileNumber}`);
            if (exists) throw new Error('User already exists, please sing in.');

            const res = await requestOtp.mutateAsync({
                mobile_number: `${data.mobileNumber}`,
            });
            if (typeof res === 'object' && 'error' in res)
                throw new Error(res.error);

            const path = structUrl('/otp', {
                carpark,
                charger,
                mobile: data.mobileNumber,
                email: data.email,
                name: data.name,
                car_model: data.carModel,
                car_plate_no: data.carPlateNo,
                mode: 'register',
            });
            router.push(path);
        } catch (e) {
            const message =
                e instanceof Error ? e.message : 'Failed to request OTP';
            setErrMsg(message);
            alert(message);
        } finally {
            setLoading(false);
        }
    }

    const errorMessage = useMemo(() => {
        let messages: string[] = [];

        Object.values(form.formState.errors).forEach((v) => {
            if (v.message) messages.push(v.message);
        });

        return messages.join(', ');
    }, [form.formState.errors]);

    // Handle form checking error
    useEffect(() => {
        if (errorMessage && errorMessage.length > 0) alert(errorMessage);
    }, [errorMessage]);

    return (
        <FormProvider {...form}>
            <form
                id="register-form"
                onSubmit={form.handleSubmit(handleRegister)}
                className="px-10 mt-10 flex flex-col gap-y-8 scroll-m-20 overflow-hidden"
            >
                <div>
                    <p className="mb-2 text-xl text-center">
                        Register as a Volvo Friend to get{' '}
                        <span className="text-2xl">HK$200</span> RHKYC credit
                    </p>
                    <p className="text-sm text-[#bb834a] text-center">
                        {errorMessage}
                        {errMsg}
                    </p>
                </div>

                <Input placeholder="RHKYC Members Name" name="name" />

                <Input
                    type="number"
                    placeholder="Mobile No."
                    name="mobileNumber"
                />

                <Input placeholder="Email" name="email" />

                <Input placeholder="Car Model" name="carModel" />

                <Input placeholder="Car Plate No." name="carPlateNo" />

                <label className="flex items-center gap-x-2">
                    <input
                        className="h-3 w-3 border"
                        type="checkbox"
                        onChange={() => setTncConfirm(!tncConfirm)}
                        checked={tncConfirm}
                    />
                    <span className="text-sm">
                        I agree to be bound by the{' '}
                        <a href="/tnc" className="underline">
                            terms and conditions
                        </a>
                    </span>
                </label>

                <label className="flex items-base gap-x-2">
                    <input
                        className="h-3 w-3 border mt-2"
                        type="checkbox"
                        onChange={() => setPrivacyConfirm(!privacyConfirm)}
                        checked={privacyConfirm}
                    />
                    <span className="text-sm">
                        I understand my mobile phone no. will be used solely for
                        verification purposes. My information will not be
                        disclosed or used for any marketing communications.
                    </span>
                </label>

                <div className="w-full flex items-center justify-center">
                    <ReCaptcha
                        sitekey={
                            process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string
                        }
                        onChange={setCaptcha}
                    />
                </div>

                <Button
                    loading={requestOtp.isPending || loading}
                    color="primary"
                >
                    Register
                </Button>

                <div className="hidden text-sm items-start justify-center gap-x-2">
                    <p>*</p>
                    <div>
                        <p>
                            If clicked ‘submit’, you are consent to the use of
                            personal data provided above by Wearnes Motors (HK)
                            Limited for receiving the latest information from
                            Volvo Cars Hong Kong, as well as for future business
                            or promotional purposes.{' '}
                        </p>
                        <Link
                            className="text-blue-500 underline mt-2"
                            href="/tnc"
                        >
                            Terms & Conditions
                        </Link>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default RegisterForm;
