import { useRequestOtp } from '@/app/hooks/cms';
import { LoginDTO, loginResolver } from '@/app/types';
import { checkCarpark, structUrl } from '@/app/utils/helpers';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../button';
import Input from '../input';
import { checkUserExists } from '@/app/api/cms/auth/service';

const SigninForm: FC = () => {
    const router = useRouter();
    const searchParams = useSearchParams();
    const carpark = searchParams.get('carpark');
    const charger = searchParams.get('charger');

    const [errMsg, setErrMsg] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const requestOtp = useRequestOtp();

    const form = useForm<LoginDTO>({
        resolver: loginResolver,
        mode: 'onChange',
        defaultValues: {
            mobileNumber: '',
        },
    });

    async function handleLogin(data: LoginDTO) {
        setErrMsg('');

        // if (!carpark) {
        //     setErrMsg('Invalid Carpark ID');
        //     return;
        // }

        // const valid = checkCarpark(carpark);

        // if (!valid) {
        //     setErrMsg('Carpark ID not availalbe');
        //     return;
        // }

        try {
            setLoading(true);
            const exists = await checkUserExists(`${data.mobileNumber}`);
            if (!exists)
                throw new Error(
                    'User not exists, please register or use guest login.'
                );

            const res = await requestOtp.mutateAsync({
                mobile_number: `${data.mobileNumber}`,
            });
            if (typeof res === 'object' && 'error' in res)
                throw new Error(res.error);

            const path = structUrl('/otp', {
                carpark,
                charger,
                mobile: data.mobileNumber,
                mode: 'signin',
            });
            router.push(path);
        } catch (e) {
            const message =
                e instanceof Error ? e.message : 'Failed to request OTP';
            setErrMsg(message);
            alert(message);
        } finally {
            setLoading(false);
        }
    }

    const errorMessage = useMemo(() => {
        let messages: string[] = [];

        Object.values(form.formState.errors).forEach((v) => {
            if (v.message) messages.push(v.message);
        });

        return messages.join(', ');
    }, [form.formState.errors]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleLogin)}
                className="px-10 mt-16 flex flex-col gap-y-8"
            >
                <div id="login">
                    <p className="mb-2 text-xl font-medium text-center">
                        Welcome back!
                    </p>
                    <p className="text-sm text-[#bb834a] text-center">
                        {errorMessage}
                        {errMsg}
                    </p>
                </div>

                <Input
                    type="number"
                    placeholder="Mobile No."
                    name="mobileNumber"
                />

                {/* <Input
          placeholder='Email'
          type='string'
          name="email" /> */}

                <Button
                    type="submit"
                    loading={requestOtp.isPending || loading}
                    color="primary"
                >
                    Login
                </Button>

                <div className="text-sm items-start justify-center gap-x-2 hidden">
                    <p>*</p>
                    <div>
                        <p>
                            If clicked ‘submit’, you are consent to the use of
                            personal data provided above by Wearnes Motors (HK)
                            Limited for receiving the latest information from
                            Volvo Cars Hong Kong, as well as for future business
                            or promotional purposes.{' '}
                        </p>
                        <Link
                            className="text-blue-500 underline mt-2"
                            href="/tnc"
                        >
                            Terms & Conditions
                        </Link>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default SigninForm;
