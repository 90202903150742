'use client';
import { useEffect } from 'react';
import RegisterForm from '../components/forms/register';
import SigninForm from '../components/forms/signin';
import { useDecodeJwt } from '../hooks/cms';
import HomeLayout from '../layouts/home-layout';
import { useRouter, useSearchParams } from 'next/navigation';
import { structUrl } from '../utils/helpers';

export default function Home() {
    const router = useRouter();
    const params = useSearchParams();
    const carpark = params.get('carpark');
    const charger = params.get('charger');
    const { data: userObj, isLoading: userObjLoading } = useDecodeJwt();

    useEffect(() => {
        if (!userObjLoading && userObj !== null && userObj?.user?.car_plate_no) {
            const path = structUrl('/app', { carpark, charger });
            router.push(path);
        }
    }, [userObjLoading, userObj, carpark, charger]);

    return (
        <HomeLayout>
            <RegisterForm />
            <SigninForm />
        </HomeLayout>
    );
}
